import {useCallback, useMemo} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment-timezone';

import {api} from '~/shared/services/bffService';
import FutureOrder from '~/shared/components/FutureOrder';
import {createLogger} from '~/shared/logging';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {MutatedFutureOrderAvailableDatesAndTimes} from '~/shared/store/models/FutureOrderAvailableDatesAndTimes';
import {
  selectShoppingCartDishes,
  selectCurrentOrderDateAndTime,
  selectCurrentRestaurant,
  selectIsFutureOrderEnabled,
  selectDishesAdditionalDetails,
  selectCurrentAddress,
  selectFutureOrderAvailableDatesAndTimes,
  selectShoppingCartDeliveryFeeFromBilling,
  selectCurrentDeliveryMethod,
  selectOrderDeliveryRule,
  selectCurrentRestaurantAvailableDeliveryRules,
  selectIsInitialOrderForCurrentRes,
  selectIsBenefitsHighlightEnabled,
} from '~/shared/store/selectors';
import {getDateTimeString} from '~/shared/utils/dates';
import {
  setCurrentModal,
  setCurrentOrderDateAndTime,
  setDeliveryRule,
  setFetchedCategories,
  setInitialOrder,
  setMenuLoading,
} from '~/shared/store/actions';
import transformMenuCategories, {MenuProps} from '~/shared/store/storeModules/menu/util';
import {navigateToDefaultStartPage, navigateToMenuOrDishPage} from '~/shared/services/navigation';
import store from '~/shared/store';
import {UnavailableDishId} from '~/shared/services/shoppingCart';
import {Address} from '~/shared/store/models';
import {filterMissingDishesAndSubs} from '~/shared/store/storeModules/shoppingCart/util';
import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';
import {handleRefreshToken, is401Error} from '~/shared/services/auth';
import {isCurrentTimeKeyAsap} from '~/shared/utils/general';
import useOptimisticDeliveryFee from '~/shared/components/FutureOrder/useOptimisticDeliveryFee';

export interface OnSelectTimeProps {
  props: MutatedFutureOrderAvailableDatesAndTimes['times'][number];
  deliveryTimeRange: string;
  newDeliveryFee?: number;
}

const logger = createLogger('FutureOrder');

const FutureOrderContextProvider = ({children}: {children?: JSX.Element | JSX.Element[]}) => {
  const dispatch = useDispatch();
  const futureOrderAvailableDatesAndTimes = useSelector(selectFutureOrderAvailableDatesAndTimes);
  const currentOrderDateAndTime = useSelector(selectCurrentOrderDateAndTime);
  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const currentAddress = useSelector(selectCurrentAddress) as Address;
  const isFutureOrderEnabled = useSelector(selectIsFutureOrderEnabled);
  const shoppingCartDishes = useSelector(selectShoppingCartDishes);
  const isInitialOrder = useSelector(selectIsInitialOrderForCurrentRes);
  const currentDeliveryMethod = useSelector(selectCurrentDeliveryMethod);
  const currentDeliveryRule = useSelector(selectOrderDeliveryRule);
  const availableDeliveryRules = useSelector(selectCurrentRestaurantAvailableDeliveryRules);
  const isBenefitsHighlightEnabled = useSelector(selectIsBenefitsHighlightEnabled);

  const [deliveryFee, updateDeliveryFee] = useOptimisticDeliveryFee(useSelector(selectShoppingCartDeliveryFeeFromBilling));

  const shouldShowDisclaimers = useMemo(() => {
    const formattedTodayDate = moment().format('DD/MM/YYYY');

    return !futureOrderAvailableDatesAndTimes?.some(availableDate => availableDate.date === formattedTodayDate);
  }, [futureOrderAvailableDatesAndTimes]);

  const isASAP = isCurrentTimeKeyAsap(currentOrderDateAndTime?.orderTime?.timeKey);

  const handleNavigationIfNeeded = useCallback(async () => {
    const state = store.getState();
    const currentDishes = selectDishesAdditionalDetails(state);

    if (!currentDishes?.length) {
      if (!currentRestaurant) {
        navigateToDefaultStartPage();
        return;
      }
      const {id, name} = currentRestaurant;
      navigateToMenuOrDishPage({restaurantId: id, restaurantName: name});
    }
  }, [currentRestaurant]);

  const updateMenu = async (menuFromBff: MenuProps) => {
    const menu = transformMenuCategories(currentRestaurant?.id || 0, menuFromBff);
    dispatch(setFetchedCategories(menu));
    dispatch(setMenuLoading(false));
  };

  const handleMissingDishesOrSubs = async (
    unavailableDishesIds: UnavailableDishId[] | null,
    menuFromBff: MenuProps,
    orderTimes: {
      orderTime: MutatedFutureOrderAvailableDatesAndTimes['times'][number];
      orderDate: MutatedFutureOrderAvailableDatesAndTimes;
    },
  ) => {
    dispatch(
      setCurrentModal('futureOrderMissingDishModal', {
        unavailableDishesIds,
        titleKey: 'future_order_unavailable_items_title',
        descriptionKey: 'future_order_unavailable_items_description',
        buttons: {
          confirm: {
            labelKey: 'remove_items',
            onClick: async () => {
              await updateMenu(menuFromBff);
              await ManagerProvider.setOrderDateAndTime(orderTimes);
              handleNavigationIfNeeded();
            },
          },
          cancel: {
            labelKey: 'cancel',
          },
        },
      }),
    );
  };

  const trackUserFirstDateTimeSelection = () => {
    if (currentRestaurant?.id && isInitialOrder) {
      dispatch(setInitialOrder({
        isInitialOrder: false,
        restaurantId: currentRestaurant.id,
      }));
    }
  };

  // Entry point to ManagerProvider
  const currentOrderDateTimeUpdateHandler = async ({orderTime, orderDate, deliveryTimeRange, newDeliveryFee, skipRuleSwitch}: {
    orderDate: MutatedFutureOrderAvailableDatesAndTimes;
    orderTime: {timeKey: string; ruleId?: number; timeKeyLabel: string; timeRange: string};
    deliveryTimeRange?: string;
    newDeliveryFee?: number;
    // This prop is used on MobileView order type picker
    // The MwOrderTypeMenu component handles the delivery rule change. The prop is passed to avoid duplicate dispatches
    skipRuleSwitch?: boolean;
  }) => {
    const isFirstIsAsap = isCurrentTimeKeyAsap(orderTime.timeKey);
    // should be used with ManagerProvider.setOrderDateAndTime as the timekey API variable
    const _timeKey = isFirstIsAsap ? DELIVERY_RULES.ASAP.toUpperCase() : orderTime.timeKey;

    // updating date and time in ui before api requests
    dispatch(setCurrentOrderDateAndTime({orderTime, orderDate}));
    if (newDeliveryFee !== undefined) {
      updateDeliveryFee(newDeliveryFee);
    }

    if (!skipRuleSwitch) {
      dispatch(setDeliveryRule(isFirstIsAsap ? DELIVERY_RULES.ASAP : DELIVERY_RULES.FUTURE));
    }

    const dateTime = getDateTimeString(orderTime, orderDate.date);

    if (currentRestaurant?.id) {
      dispatch(setMenuLoading(true));
      const menuFromBff = await api.fetchRestaurantMenu({
        id: currentRestaurant.id,
        dateTime,
        addressId: currentAddress?.addressId,
      });
      const unavailableDishesIds = filterMissingDishesAndSubs(menuFromBff.categories, shoppingCartDishes);

      const orderTimes = {
        orderDate,
        orderTime: {
          timeKey: _timeKey,
          ruleId: orderTime.ruleId,
          timeRange: orderTime.timeRange,
          timeKeyLabel: isFirstIsAsap && deliveryTimeRange ?
            deliveryTimeRange :
            orderTime.timeKeyLabel,
        },
      };

      if (!unavailableDishesIds?.length) {
        updateMenu(menuFromBff);
        await ManagerProvider.setOrderDateAndTime(orderTimes);
        return;
      }
      handleMissingDishesOrSubs(unavailableDishesIds, menuFromBff, orderTimes);
    }
  };

  const onSelectTime = async ({props: {timeKey, ...timeProps}, deliveryTimeRange, newDeliveryFee}: OnSelectTimeProps) => {
    trackUserFirstDateTimeSelection();
    const isSelectedSameTime =
      (isCurrentTimeKeyAsap(timeKey) && isCurrentTimeKeyAsap(currentOrderDateAndTime.orderTime.timeKey)) ||
      timeKey === currentOrderDateAndTime.orderTime?.timeKey;

    if (isSelectedSameTime) {
      return;
    }

    try {
      await currentOrderDateTimeUpdateHandler({
        orderDate: currentOrderDateAndTime?.orderDate,
        orderTime: {timeKey, ...timeProps},
        deliveryTimeRange,
        newDeliveryFee,
      });
    } catch (error) {
      if (is401Error(error)) {
        handleRefreshToken(error, onSelectTime, {props: {timeKey, ...timeProps}, deliveryTimeRange});
        return;
      }

      logger.error(new Error('setOrderDateAndTime in onSelectTime failed'), {error});
    }
  };

  const onSelectDate = async (orderDate: MutatedFutureOrderAvailableDatesAndTimes) => {
    trackUserFirstDateTimeSelection();
    const isSelectedSameDate = orderDate.date === currentOrderDateAndTime.orderDate.date;
    if (isSelectedSameDate) {
      return;
    }

    try {
      await currentOrderDateTimeUpdateHandler({
        orderDate,
        orderTime: orderDate.times[0],
      });
    } catch (error) {
      if (is401Error(error)) {
        handleRefreshToken(error, onSelectDate, orderDate);
        return;
      }

      logger.error(new Error('setOrderDateAndTime in onSelectDate failed'), {error});
    }
  };

  const onSelectDateAndTime = async ({orderDate, orderTime, skipRuleSwitch}: {orderDate: MutatedFutureOrderAvailableDatesAndTimes; orderTime: OnSelectTimeProps; skipRuleSwitch?: boolean}) => {
    trackUserFirstDateTimeSelection();
    const {props: {timeKey, ...timeProps}, deliveryTimeRange, newDeliveryFee} = orderTime;

    const isSelectedSameDate = orderDate.date === currentOrderDateAndTime.orderDate?.date;
    const isSelectedSameTime =
      (isCurrentTimeKeyAsap(timeKey) && isCurrentTimeKeyAsap(currentOrderDateAndTime.orderTime?.timeKey)) ||
      timeKey === currentOrderDateAndTime.orderTime?.timeKey;

    if (isSelectedSameTime && isSelectedSameDate) {
      return;
    }

    try {
      await currentOrderDateTimeUpdateHandler({
        orderDate,
        orderTime: {timeKey, ...timeProps},
        deliveryTimeRange,
        newDeliveryFee,
        skipRuleSwitch,
      });
    } catch (error) {
      if (is401Error(error)) {
        handleRefreshToken(error, onSelectDateAndTime, {orderDate, orderTime});
        return;
      }

      logger.error(new Error('setOrderDateAndTime in onSelectDate failed'), {error});
    }
  };

  const futureOrderAvailableDatesAndTimesAvailable = Boolean(futureOrderAvailableDatesAndTimes?.[0]?.times?.[0]);

  return (
    <FutureOrder.Provider
      value={{
        onSelectTime,
        onSelectDate,
        onSelectDateAndTime,
        currentOrderDateAndTime,
        futureOrderAvailableDatesAndTimes,
        isFutureOrderEnabled,
        futureOrderAvailableDatesAndTimesAvailable,
        isASAP,
        currentRestaurant,
        isInitialOrder,
        shouldShowDisclaimers,
        currentDeliveryFee: deliveryFee,
        currentDeliveryRule,
        currentDeliveryMethod,
        availableDeliveryRules,
        trackUserFirstDateTimeSelection,
        isBenefitsHighlightEnabled,
      }}
    >
      {children}
    </FutureOrder.Provider>
  );
};
export default FutureOrderContextProvider;
