import {find, isEmpty, isEqual, reject} from 'lodash';
import {createSelector} from 'reselect';

import OrderManager from '~/shared/managers/OrderManager';
import {is401Error, handleRefreshToken, logout} from '~/shared/services/auth';
import {MutatedFutureOrderAvailableDatesAndTimes} from '~/shared/store/models/FutureOrderAvailableDatesAndTimes';
import {Address, LocalAddress, Order, OrderData, ShoppingCartDish} from '~/shared/store/models';
import {DELIVERY_RULES, DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {ApiResponse} from '~/shared/services/apiService';
import {ApiError, isApiError} from '~/shared/services/apiErrorService';
import {getCurrentLocation} from '~/shared/router';
// eslint-disable-next-line @welldone-software/modules-engagement
import {updateIntercomWithFirstDish} from '~/app/components/SupportChat/Intercom';
import {CollectionOrderTypeViewTags} from '~/shared/store/models/Carousel';

import {eventNames, raise} from '../../events';
import {createLogger} from '../../logging';
import {getDefaultAddress} from '../../services/addressHelper';
import {navigateToDefaultStartPage, navigateToMenuOrDishPage} from '../../services/navigation';
import {getCompanyDetails} from '../../services/payments';
import store from '../../store';
import {
  getAvailablePayments,
  getDishAssignedUsers,
  restrictedSharedActions,
  searchRestaurantsIfNeeded,
  SubmitOrderPayload,
  submitOrder as submitOrderAction,
  addPayment,
  removePayment,
  setMenuLoading,
  setMissingDishesIds,
  setIsNdeAnalyticsIdle,
} from '../../store/actions';
import {
  selectAllAddresses,
  selectAvailablePayments,
  selectCheckoutPayments,
  selectCurrentAddress,
  selectCurrentAddressKey,
  selectCurrentDeliveryMethod,
  selectCurrentOrderDateAndTime,
  selectCurrentRestaurantId,
  selectDishAssignedUsersLoaded,
  selectMissingDishIds,
  selectRestaurantsMainListData,
  selectShoppingCartDishes,
  selectIsCurrentAddressIsDinningRoom,
} from '../../store/selectors';
import {UserCredentials} from '../../store/storeModules/restrictedSharedActions';
import {addressPartsFromKey, AddressPartsFromKey, findAddressByAddressKey} from '../../utils/address';
import AddressManager from '../AddressManager';
import RestaurantManager from '../RestaurantManager/RestaurantManager';
import UserManager from '../UserManager';

import ManagerProviderHelper, {CHANGE_RESTAURANT_IN_MENU_PAGE, analyticsReachedRestaurantMinimum} from './ManagerProviderHelper';

const logger = createLogger('ManagerProvider');

const selectCurrentAddressByAddressKey = createSelector(
  selectAllAddresses,
  selectCurrentAddressKey,
  findAddressByAddressKey,
);

const ManagerProvider = {
  // #region private methods
  _changeDishQuantity: async (shoppingCartDishId: ShoppingCartDish['dishId'], diff: number) => {
    const oldDishes = selectShoppingCartDishes(store.getState());
    return OrderManager.changeDishes({
      dishList: oldDishes.map(currentDish =>
        (currentDish.shoppingCartDishId === shoppingCartDishId
          ? {...currentDish, quantity: currentDish.quantity + diff}
          : currentDish),
      ),
    });
  },
  // #endregion

  // #region public methods
  changeShoppingCartGuid: async ({
    shoppingCartGuid: newShoppingCartGuid,
    updateOrder,
  }: {
    shoppingCartGuid: string;
    updateOrder: boolean;
  }) => {
    logger.verbose('changing shopping cart guid', {newShoppingCartGuid, updateOrder});
    OrderManager.setShoppingCartGuid(newShoppingCartGuid);
    if (!updateOrder) {
      return;
    }

    store.dispatch(restrictedSharedActions.clearOrderData());
    const state = store.getState();

    const currentDeliveryMethod = selectCurrentDeliveryMethod(state);
    if (currentDeliveryMethod) {
      // Todo: revisit - possibly use OrderManager.changeDeliveryMethod instead
      await ManagerProvider.changeDeliveryMethod({
        deliveryMethod: currentDeliveryMethod,
        force: true,
        searchRestaurants: false,
      });
    }

    const currentCurrentAddressKey = selectCurrentAddressKey(state);
    const address = currentCurrentAddressKey && addressPartsFromKey(currentCurrentAddressKey);
    if (address) {
      // Todo: revisit - possibly use OrderManager.setAddressInOrder instead
      await ManagerProvider.changeAddress({address, force: true, searchRestaurants: false});
    }

    await OrderManager.setUserInOrder();

    if (currentDeliveryMethod && address) {
      await OrderManager.isIdle();
      await store.dispatchThunk(
        searchRestaurantsIfNeeded({
          deliveryMethod: currentDeliveryMethod,
          addressKey: currentCurrentAddressKey,
          force: true,
        }),
      );
    }

    await OrderManager.getOrderData();
  },

  // #region user related
  // Todo: revisit - apparently can only pass `isAutomaticLogin` and if it's false reinitialize the state.
  login: async (userCredentials: UserCredentials = {}, {isAutomaticLogin = false, reInitializeState = true} = {}) => {
    const userRes = await UserManager.login(userCredentials, isAutomaticLogin);
    const user = {
      ...userRes,
    };

    const isDeletedUser = !user.userActive;
    if (isDeletedUser) {
      logout();
      return;
    }

    if (!selectDishAssignedUsersLoaded(store.getState())) {
      store.dispatchThunk(getDishAssignedUsers());
    }

    await ManagerProvider.changeAddress({
      address: getDefaultAddress(),
      searchRestaurants: false,
    });

    if (reInitializeState) {
      // Todo: revisit - possibly use OrderManager.changeDeliveryMethod instead
      await ManagerProvider.changeDeliveryMethod({
        deliveryMethod: selectCurrentDeliveryMethod(store.getState()),
        force: true,
        searchRestaurants: false,
      });
    }

    raise(eventNames.login, {user});
    return user;
  },

  registerUser: async (signUpCredentials: Parameters<typeof UserManager.registerUser>[0], registerCompanyUser?: boolean) => {
    const user = await UserManager.registerUser(signUpCredentials, registerCompanyUser);
    await ManagerProvider.changeAddress({address: getDefaultAddress(), searchRestaurants: false});
    raise(eventNames.login, {user});
    return user;
  },
  // #endregion

  // #region address/delivery method related
  changeAddress: async ({address, previousAddress, force, searchRestaurants = true}: ChangeAddressPayload) => {
    store.dispatch(setIsNdeAnalyticsIdle(false));
    try {
      const currentAddress = selectCurrentAddress(store.getState());
      const newAddress = await AddressManager.changeAddress(address, force);

      if (!newAddress) {
        return;
      }

      await OrderManager.setAddressInOrder(newAddress);

      const isCurrentAddressIsDinningRoom = selectIsCurrentAddressIsDinningRoom(store.getState());
      if (isCurrentAddressIsDinningRoom) {
        await ManagerProvider.changeDeliveryMethod({deliveryMethod: DeliveryMethods.PICKUP});
      }

      if (searchRestaurants) {
        await ManagerProvider.reSearchRestaurants();
      } else {
        store.dispatch(setIsNdeAnalyticsIdle(true));
      }

      raise(eventNames.addressChanged, {newAddress, previousAddress: previousAddress || currentAddress});
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, ManagerProvider.changeAddress, {
          address,
          previousAddress,
          force,
          searchRestaurants,
        });
      }
    }
  },

  updateAddress: async (address: Address) => {
    try {
      const {isUpdatingCurrentAddress, previousAddress} = await AddressManager.updateAddress(address);
      if (isUpdatingCurrentAddress) {
        ManagerProvider.changeAddress({address, previousAddress, force: true});
      }
    } catch (e) {
      if (isApiError(e)) {
        await handleRefreshToken(e as ApiError, ManagerProvider.updateAddress, address);
      }
    }
  },

  changeDeliveryMethod: async ({
    deliveryMethod: newDeliveryMethodFromArgs,
    force,
    searchRestaurants = true,
    reChangeRestaurant = true,
  }: {
    deliveryMethod: string;
    force?: boolean;
    searchRestaurants?: boolean;
    reChangeRestaurant?: boolean;
  }) => {
    store.dispatch(setIsNdeAnalyticsIdle(false));
    const newDeliveryMethod = await OrderManager.changeDeliveryMethod(newDeliveryMethodFromArgs, force);
    if (searchRestaurants) {
      ManagerProvider.reSearchRestaurants({reChangeRestaurant});
    } else {
      store.dispatch(setIsNdeAnalyticsIdle(true));
    }
    if (reChangeRestaurant && newDeliveryMethod === DeliveryMethods.DELIVERY) {
      const currentOrderTime = selectCurrentOrderDateAndTime(store.getState());
      if (isEmpty(currentOrderTime)) {
        RestaurantManager.setDefaultFutureOrderForRestaurant();
      }
    }
    raise(eventNames.deliveryMethodChanged, newDeliveryMethod);
  },
  // #endregion

  // #region restaurants related
  changeRestaurant: async ({
    restaurantId,
    force,
    forceKeepDishes,
    restaurantQuery,
  }: {
    restaurantId: number;
    force?: boolean;
    forceKeepDishes?: boolean;
    restaurantQuery?: {
      allowDeliveryRuleChange?: boolean;
      orderViewTag?: CollectionOrderTypeViewTags;
    };
  }) => {
    try {
      const changeRestaurantResult = await RestaurantManager.changeRestaurant(restaurantId, force, forceKeepDishes, restaurantQuery);
      if (!changeRestaurantResult) {
        return;
      }
      const state = store.getState();
      const shoppingCartDishes = selectShoppingCartDishes(state);

      const {shouldClearDishes, newRestaurant} = changeRestaurantResult;

      const missingDishesIds = selectMissingDishIds(store.getState());

      if (missingDishesIds?.length && !shouldClearDishes) {
        const dishes = shoppingCartDishes.filter(scDish => !missingDishesIds.includes(scDish.shoppingCartDishId));
        OrderManager.changeDishes({dishList: dishes});
        store.dispatch(setMissingDishesIds([]));
      }

      try {
        ManagerProviderHelper.ensureShoppingCartDishesInMenu();
      } catch (e) {
        await OrderManager.changeDishes({dishList: []});
      }
      
      RestaurantManager.userRelatedUpdateUponRestaurantChange(newRestaurant.id);

      store.dispatch(setMenuLoading(false));

      raise(eventNames.restaurantChanged, newRestaurant);
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, ManagerProvider.changeRestaurant, {
          restaurantId,
          force: true,
          forceKeepDishes: true,
        });
        return;
      }

      throw error;
    }
  },

  reSearchRestaurants: async ({
    currentRestaurantId,
    force,
    reChangeRestaurant = true,
  }: {
    force?: boolean;
    reChangeRestaurant?: boolean;
    currentRestaurantId?: number;
  } = {}) => {
    try {
      const state = store.getState();
      const prevRestaurantsData = selectRestaurantsMainListData(state);
      const newRestaurantsData = await RestaurantManager.reSearchRestaurants(OrderManager.isIdle(), force);
      const {routeName} = getCurrentLocation();

      if (reChangeRestaurant && (force || prevRestaurantsData !== newRestaurantsData)) {
        const restaurantId = currentRestaurantId || selectCurrentRestaurantId(state);
        if (restaurantId && CHANGE_RESTAURANT_IN_MENU_PAGE.includes(routeName)) {
          // prevents fetchRestaurant when leaving menu page on fresh load,
          // and re-fetchRestaurant when navigating to checkout page
          await ManagerProvider.changeRestaurant({restaurantId, force: true});
        }
      }
      store.dispatch(setIsNdeAnalyticsIdle(true));
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, ManagerProvider.reSearchRestaurants, {
          currentRestaurantId,
          force: true,
          reChangeRestaurant,
        });
      }
    }
  },
  // #endregion

  // #region order related
  isIdle: (...params: Parameters<typeof OrderManager.isIdle>) => {
    return OrderManager.isIdle(...params);
  },

  getOrderData: (...params: Parameters<typeof OrderManager.getOrderData>) => {
    return OrderManager.getOrderData(...params);
  },

  setPermitCodeInOrder: (...params: Parameters<typeof OrderManager.setPermitCodeInOrder>) => {
    return OrderManager.setPermitCodeInOrder(...params);
  },

  changeOrderPayments: async (...params: Parameters<typeof OrderManager.changeOrderPayments>) => {
    try {
      return await OrderManager.changeOrderPayments(...params);
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, ManagerProvider.changeOrderPayments, ...params as Parameters<typeof OrderManager.changeOrderPayments>);
        return;
      }

      logger.error('unexpected error on changeOrderPayments', {err: error});
      navigateToDefaultStartPage();
    }
  },

  changeTipAmount: (...params: Parameters<typeof OrderManager.changeTipAmount>) => {
    return OrderManager.changeTipAmount(...params);
  },

  changeCoupon: (...params: Parameters<typeof OrderManager.changeCoupon>) => {
    return OrderManager.changeCoupon(...params);
  },

  reorder: async (...params: Parameters<typeof OrderManager['reorder']>) => {
    const reorderResData = await OrderManager.reorder(...params);
    const {routeParams} = getCurrentLocation();

    if (!reorderResData) {
      return;
    }

    const {restaurantId, restaurantName} = reorderResData;
    const force = restaurantId !== Number(routeParams?.restaurantId || '0');

    ManagerProvider.changeRestaurant({
      restaurantId,
      forceKeepDishes: true,
      force,
      restaurantQuery: {
        allowDeliveryRuleChange: true,
      },
    });
    navigateToMenuOrDishPage({restaurantId, restaurantName});
  },

  updateOrderData: async (
    paymentRemarkConfiguration: Omit<ReturnType<typeof getCompanyDetails>, 'startTime' | 'endTime'> | null,
    paymentsRemarks: Record<string, string>,
  ) => {
    logger.verbose('updateOrderData is called');
    const currentState = store.getState();

    await OrderManager.changeDeliveryMethod(selectCurrentDeliveryMethod(currentState));
    await ManagerProvider.changeAddress({
      address: selectCurrentAddressByAddressKey(currentState),
      searchRestaurants: true,
    });
    await ManagerProvider.changeRestaurant({restaurantId: selectCurrentRestaurantId(currentState)});
    await OrderManager.changeDishes({dishList: selectShoppingCartDishes(currentState)});

    const availablePayments = selectAvailablePayments(currentState);
    const checkoutPayments = selectCheckoutPayments(currentState);
    const paymentsList = checkoutPayments.length
      ? checkoutPayments.filter(payment => payment.sum > 0)
      : availablePayments?.filter(payment => payment.assigned === true && payment.sum > 0);

    const paymentsToSubmit = paymentRemarkConfiguration
      ? paymentsList?.map(payment => {
        const {prefix} = paymentRemarkConfiguration;
        const relevantPaymentRemarks = paymentsRemarks[`${payment.cardId}_${payment.paymentMethod}`];
        const newRemarks =
            prefix && relevantPaymentRemarks
              ? relevantPaymentRemarks.replace(prefix.toLowerCase(), prefix)
              : relevantPaymentRemarks;
        const shouldAddPrefix = prefix && newRemarks.indexOf(prefix) !== 0;

        return {...payment, remarks: shouldAddPrefix ? prefix + newRemarks : newRemarks};
      })
      : paymentsList;

    if (paymentsToSubmit) {
      await OrderManager.changeOrderPayments({payments: paymentsToSubmit});
    }
    return OrderManager.isIdle();
  },

  setOrderDateAndTime: async ({orderDate, orderTime}: DateAndTimePayload) => {
    await OrderManager.setOrderDateAndTime(orderDate, orderTime);
    await ManagerProvider.chooseAndSetBestDiscountCouponValueInOrder();
  },

  chooseAndSetBestDiscountCouponValueInOrder: async () => {
    let result: ApiResponse<OrderData> | undefined;
    const setCoupon = async () => {
      result = await OrderManager.chooseAndSetBestDiscountCouponValueInOrder();
      // make sure to get payments after setting the best coupon in order
      // so the assigned payments will be divided correctly
      await store.dispatchThunk(getAvailablePayments()); // Todo: should probably be called elsewhere
    };

    try {
      await setCoupon();
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, () => setCoupon());
        return;
      }
    }

    return result;
  },

  submitOrder: async (orderToSubmit: SubmitOrderPayload) => {
    await OrderManager.isIdle();

    try {
      const submitResult: ApiResponse<Order> = await store.dispatchThunk<ReturnType<typeof submitOrderAction>, ApiResponse<Order>>(
        submitOrderAction(orderToSubmit),
      );
      return submitResult;
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, () => ManagerProvider.submitOrder(orderToSubmit));
        return;
      }
      
      throw error;
    }
  },
  // #endregion

  // #region dish related
  incrementDishQuantity: async (shoppingCartDishId: ShoppingCartDish['dishId']) => {
    const orderData = await ManagerProvider._changeDishQuantity(shoppingCartDishId, 1);

    analyticsReachedRestaurantMinimum();

    return orderData;
  },

  decrementDishQuantity: async (shoppingCartDishId: ShoppingCartDish['dishId']) => {
    const orderData = await ManagerProvider._changeDishQuantity(shoppingCartDishId, -1);

    analyticsReachedRestaurantMinimum();

    return orderData;
  },

  upsertDish: async (rawDish: ShoppingCartDish) => {
    const newDish: ShoppingCartDish = {
      ...rawDish,
      assignedUserId: rawDish.assignedUserId || 0,
      choices: rawDish.choices || [],
      quantity: rawDish.quantity || 1,
    };
    const oldDishes = selectShoppingCartDishes(store.getState());
    const {shoppingCartDishId} = rawDish;
    if (isEqual(newDish, find(oldDishes, {shoppingCartDishId}))) {
      return oldDishes;
    }

    const newDishList = [newDish, ...reject(oldDishes, {shoppingCartDishId})];
    const result = await OrderManager.changeDishes({dishList: newDishList});
    const checkoutPaymentToAdd = await ManagerProviderHelper.getMissingCheckoutPaymentUponDishInsert(newDish);
    store.dispatch(addPayment(checkoutPaymentToAdd));
    // calculate the best coupon for the first dish we add to the shopping cart
    const isFirstDish = oldDishes.length === 0;
    if (isFirstDish) {
      await OrderManager.chooseAndSetBestDiscountCouponValueInOrder();
      updateIntercomWithFirstDish();
    }

    analyticsReachedRestaurantMinimum();

    return result;
  },

  removeDish: async (shoppingCartDishId: ShoppingCartDish['dishId']) => {
    if (!shoppingCartDishId) {
      return;
    }
    const oldDishes = selectShoppingCartDishes(store.getState());
    const newDishList = oldDishes.filter(d => d.shoppingCartDishId !== shoppingCartDishId);
    if (newDishList.length === oldDishes.length) return;

    const result = await OrderManager.changeDishes({dishList: newDishList});
    const paymentToRemove = await ManagerProviderHelper.getExcessPaymentUponDishRemove(oldDishes, shoppingCartDishId);
    if (paymentToRemove) {
      store.dispatch(removePayment(paymentToRemove.cardId));
    }
    analyticsReachedRestaurantMinimum();
    return result;
  },

  setDefaultFutureOrderAfterSetRestaurant: RestaurantManager.setDefaultFutureOrderForRestaurant,

  addAllPaymentsForReorder: async (dish: ShoppingCartDish) => {
    const checkoutPaymentToAdd = await ManagerProviderHelper.getMissingCheckoutPaymentUponDishInsert(dish);
    store.dispatch(addPayment(checkoutPaymentToAdd));
  },

  switchDeliveryRuleType: async ({
    restaurantId,
    deliveryRuleType,
    isCheckout,
  }: {
    restaurantId: number;
    deliveryRuleType: DELIVERY_RULES;
    isCheckout?: boolean;
  }) => {
    try {
      await OrderManager.setRestaurantInOrder(restaurantId, {deliveryRuleType});
      if (isCheckout) {
        store.dispatchThunk(getAvailablePayments());
      }
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, ManagerProvider.switchDeliveryRuleType, {restaurantId, deliveryRuleType, isCheckout});
        return;
      }

      logger.error('unexpected error on switchDeliveryRuleType', {error});
      navigateToDefaultStartPage();
    }
  },
};

interface ChangeAddressPayload {
  address?: Address | LocalAddress | AddressPartsFromKey;
  previousAddress?: Address | LocalAddress | AddressPartsFromKey;
  force?: boolean;
  searchRestaurants?: boolean;
}

interface DateAndTimePayload {
  orderDate?: MutatedFutureOrderAvailableDatesAndTimes;
  orderTime?: MutatedFutureOrderAvailableDatesAndTimes['times'][number];
}

export default ManagerProvider;
